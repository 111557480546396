@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@layer base {
  html {
    font-family: Montserrat, system-ui, sans-serif;
  }
}

.datepicker-dropdown {
  @apply relative top-0 left-0 z-30;

  top: 0 !important;
  left: 0 !important;

  &.datepicker-orient-top {
    @apply pt-0 pb-2;
  }

  .datepicker-picker {
    @apply shadow-lg;
  }
}

.datepicker-picker {
  @apply inline-block rounded-md bg-white;

  span {
    @apply rounded-md;

    display: block;
    flex: 1;
    border: 0;
    cursor: default;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.datepicker-main {
  @apply py-2 pb-4 px-4;
}

.datepicker-footer {
  background-color: whitesmoke;
}

.datepicker-controls, .datepicker-view, .datepicker-view .days-of-week, .datepicker-grid {
  display: flex;
}

.datepicker-grid {
  flex-wrap: wrap;
}

.datepicker-view .dow, .datepicker-view .days .datepicker-cell {
  flex-basis: 14.28571%;
}

.datepicker-view.datepicker-grid .datepicker-cell {
  flex-basis: 25%;
}

.datepicker-view .week, .datepicker-cell {
  height: 2.25rem;
  line-height: 2.25rem;
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1);
  background-color: whitesmoke;
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-header .datepicker-controls {
  @apply px-4 py-2 pt-4;
}

.datepicker-controls {
  .button {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: none;
    background-color: white;
    cursor: pointer;
    padding: calc(0.375em - 1px) 0.75em;
    height: 2.25em;
    vertical-align: top;
    text-align: center;
    line-height: 1.5;
    white-space: nowrap;
    color: #363636;
    font-size: 1rem;
  }

  .button:focus, .button:active {
    outline: none;
  }

  .button:hover {
    border-color: #b5b5b5;
    color: #363636;
  }

  .button:focus {
    border-color: #3273dc;
    color: #363636;
  }

  .button:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
  }

  .button:active {
    border-color: #4a4a4a;
    color: #363636;
  }

  .button[disabled] {
    cursor: not-allowed;
  }

  .view-switch {
    flex: auto;
  }

  .prev-btn, .next-btn {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
    width: 2.25rem;
  }

  .prev-btn.disabled, .next-btn.disabled {
    visibility: hidden;
  }
}

.datepicker-header .datepicker-controls {
  .button {
    border-color: transparent;
    font-weight: bold;
  }

  .button:hover {
    background-color: #f9f9f9;
  }

  .button:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
  }

  .button:active {
    background-color: #f2f2f2;
  }

  .button[disabled] {
    box-shadow: none;
  }


}

.datepicker-footer .datepicker-controls {
  .button {
    margin: calc(0.375rem - 1px) 0.375rem;
    border-radius: 2px;
    width: 100%;
    font-size: 0.75rem;
  }
}

.datepicker-view {
  .dow {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
    font-weight: 700;
  }

  .week {
    width: 2.25rem;
    color: #b5b5b5;
    font-size: 0.75rem;
  }
}

@media (max-width: 22.5rem) {
  .datepicker-view .week {
    width: 1.96875rem;
  }
}

.datepicker-grid {
  width: 15.75rem;
}

@media (max-width: 22.5rem) {
  .calendar-weeks + .days .datepicker-grid {
    width: 13.78125rem;
  }
}

.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.datepicker-cell.focused:not(.selected) {
  background-color: #e8e8e8;
}

.datepicker-cell.selected, .datepicker-cell.selected:hover {
  @apply bg-primary text-white font-bold;
}

.datepicker-cell.disabled {
  color: #dbdbdb;
}

.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
  color: #7a7a7a;
}

.datepicker-cell.prev.selected, .datepicker-cell.next.selected {
  color: #e6e6e6;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: whitesmoke;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #eeeeee;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e8e8e8;
}

.datepicker-cell.today:not(.selected) {
  background-color: #00d1b2;
}

.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}

.datepicker-cell.today.focused:not(.selected) {
  background-color: #00c4a7;
}

.datepicker-cell.range-start:not(.selected), .datepicker-cell.range-end:not(.selected) {
  background-color: #b5b5b5;
  color: #fff;
}

.datepicker-cell.range-start.focused:not(.selected), .datepicker-cell.range-end.focused:not(.selected) {
  background-color: #afafaf;
}

.datepicker-cell.range-start {
  border-radius: 4px 0 0 4px;
}

.datepicker-cell.range-end {
  border-radius: 0 4px 4px 0;
}

.datepicker-cell.range {
  border-radius: 0;
  background-color: #dbdbdb;
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d5d5d5;
}

.datepicker-cell.range.disabled {
  color: #c2c2c2;
}

.datepicker-cell.range.focused {
  background-color: #cfcfcf;
}

.datepicker-view.datepicker-grid .datepicker-cell {
  height: 4.5rem;
  line-height: 4.5rem;
}

.datepicker-input.in-edit {
  border-color: #2366d1;
}

.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(35, 102, 209, 0.2);
}